"use client";

import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { AppDispatch, RootState } from "@/redux/store";
import { STRIPE_IDS } from "@/constants/subscription";
import {
  setBillingError,
  setBillingLoading,
  setBillingRedirectUrl,
  generateAlert,
  authenticateUser,
} from "./";

const environment =
  process.env.NODE_ENV === "development" ? "development" : "production";

const getStripeProductId = (
  plan: "unlimited" | "starter" | "premium" | "professional" | "business",
  frequency: "monthly" | "yearly"
) => {
  return STRIPE_IDS[environment][plan][frequency];
};

export const subscribeWithStripe = createAsyncThunk<
  string | null,
  {
    plan: "unlimited" | "starter" | "premium" | "professional" | "business";
    paymentFrequency: "monthly" | "yearly";
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "billing/subscribeWithStripe",
  async ({ plan = "premium", paymentFrequency = "yearly" }, { dispatch }) => {
    try {
      dispatch(setBillingLoading(true));
      dispatch(setBillingError(null));

      const priceId = getStripeProductId(plan, paymentFrequency);

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const requestBody: { [key: string]: any } = {
        mode: "subscription",
        priceId: priceId,
      };

      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe`,
        requestBody,
        config
      );

      return response.data.url;
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "An error occurred while subscribing to plan!";
      dispatch(setBillingLoading(false));
      dispatch(setBillingError(errorMessage));
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      return null;
    }
  }
);

export const stripeOneOffPayment = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("billing/stripeOneOffPayment", async (_, { dispatch }) => {
  try {
    dispatch(setBillingLoading(true));
    dispatch(setBillingError(null));

    const priceId =
      environment === "development"
        ? "price_1Q2NoVIegQSKNzkSfLtvmNio"
        : "price_1Q2PBsIegQSKNzkSplMaF6WC";

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const requestBody: { [key: string]: any } = {
      mode: "payment",
      priceId: priceId,
    };

    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe`,
      requestBody,
      config
    );

    dispatch(setBillingRedirectUrl(response.data.url));
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "An error occurred while processing payment!";
    dispatch(setBillingLoading(false));
    dispatch(setBillingError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const checkStripePurchaseStatus = createAsyncThunk<
  void,
  { purchaseType: "subscription" | "payment"; sessionId: string },
  { dispatch: AppDispatch; state: RootState }
>(
  "billing/checkStripePurchaseStatus",
  async ({ purchaseType, sessionId }, { dispatch }) => {
    try {
      dispatch(setBillingLoading(true));
      dispatch(setBillingError(null));

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}/billing/stripe/confirmation/${purchaseType}/${sessionId}`,
        config
      );

      dispatch(setBillingRedirectUrl("/settings/manage-subscription"));
      let successMessage = "Purchase successful!";
      if (purchaseType === "subscription") {
        successMessage = "Subscription successful!";
      }
      dispatch(authenticateUser());
      dispatch(generateAlert({ text: successMessage, type: "success" }));
    } catch (error: unknown) {
      console.info(error);
      const errorMessage = "No valid purchase found!";
      dispatch(setBillingLoading(false));
      dispatch(setBillingError(errorMessage));
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);

export const cancelSubscription = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("billing/cancelSubscription", async (_, { dispatch }) => {
  try {
    dispatch(setBillingLoading(true));
    dispatch(setBillingError(null));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/billing`, config);
    dispatch(setBillingRedirectUrl("/settings/manage-subscription"));
    dispatch(
      generateAlert({ text: "Subscription cancelled!", type: "success" })
    );
    dispatch(authenticateUser());
  } catch (error: unknown) {
    console.error(error);
    const errorMessage = "Failed to cancel subscription!";
    dispatch(setBillingLoading(false));
    dispatch(setBillingError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const checkPaypalSubscription = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch; state: RootState }
>("billing/checkPaypalSubscription", async (subscriptionId, { dispatch }) => {
  try {
    dispatch(setBillingLoading(true));
    dispatch(setBillingError(null));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/billing/paypal/subscriptions/${subscriptionId}/confirmation`,
      {},
      config
    );

    dispatch(authenticateUser());
    dispatch(setBillingRedirectUrl("/settings/manage-subscription"));
    dispatch(
      generateAlert({ text: "Subscription successful!", type: "success" })
    );
  } catch (error: unknown) {
    console.info(error);
    const errorMessage = "No valid purchase found!";
    dispatch(setBillingLoading(false));
    dispatch(setBillingError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

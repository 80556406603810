import { Nullable } from "./common";
import { UserRating, UserFollow } from "./social";
import { SubscriptionType } from "./user";

export enum TransformationType {
  AI_Text = "ai-text",
  Enhance = "enhance",
  HD = "hd",
  HD_Plus = "hd+",
  Flux_Pro = "flux-pro",
  HD_Turbo = "hd-turbo",
  InPaint = "inpaint",
  Remix = "remix",
  Turbo = "turbo",
}

export const TRANSFORMATION_TYPE_LABELS = {
  [TransformationType.HD]: "HD",
  [TransformationType.Turbo]: "Turbo",
  [TransformationType.Remix]: "Remix",
  [TransformationType.Enhance]: "Enhance",
  [TransformationType.AI_Text]: "AI Text",
  [TransformationType.HD_Plus]: "HD+",
  [TransformationType.Flux_Pro]: "HDR+",
  [TransformationType.HD_Turbo]: "HD Turbo",
  [TransformationType.InPaint]: "In-Paint",
};

export enum ViewMode {
  Public = "public",
  Private = "private",
  Restricted = "restricted",
}

export type Tag = {
  name: string;
  rank: Nullable<number>;
  isLora: boolean;
};

export type Image = {
  url: string;
  alt: string | null;
  tags?: Tag[] | string[];
  id: number;
  likesCount: number;
  userRating: Nullable<UserRating>;
  safetyRating: number;
  aestheticRating?: Nullable<number>;
};

export type Generation = {
  id: number;
  commentCount?: number;
  createdAt: string;
  inspirationImageUrls: string[];
  rawImageUrl: Nullable<string>;
  roomUUID?: string;
  subscriptionType?: SubscriptionType;
  modelUsed: TransformationType;
  payload: Record<string, unknown>;
  prompt: string;
  textPrompt: string;
  images: Image[];
  slug: Nullable<string>;
  adapters: string[];
  username: string;
  avatarUrl: Nullable<string>;
  viewMode: ViewMode;
  feedbackRating?: Nullable<string>;
  feedbackComment?: Nullable<string>;
  feedbackTooSlow?: boolean;
  feedbackWrongOutput?: boolean;
  combinedTags: Tag[];
  aspectRatioUsed: string;
};

export type Idea = {
  id: number;
  uuid: string;
  name: string;
  masks: Nullable<{ url: string; description: string }[]>;
  transformationType: TransformationType;
  generations: Generation[];
  highlightedGenerationIndex: number;
  userId: number;
  seoTitle: Nullable<string>;
  seoDescription: Nullable<string>;
  username: string;
  avatarUrl: Nullable<string>;
  subscriptionType: SubscriptionType;
  userFollow: Nullable<UserFollow>;
  createdAt: string;
  updatedAt: string;
};

export type FeedImage = Image & {
  roomUUID: string;
  slug: Nullable<string>;
  generationId: number;
  username: string;
  avatarUrl: string;
  subscriptionType: SubscriptionType;
  modelUsed: TransformationType;
};

export type GalleryImage = Image & {
  roomUUID: string;
  generationId: number;
};

export type ExploreRelatedImage = {
  id: number;
  images: Image[];
  roomUUID: string;
  slug: Nullable<string>;
  title: string;
};

export type GenerationPageResponse = {
  id: number;
  title: string;
  description: string;
  roomUUID: string;
  prompt: string;
  createdAt: string;
  username: string;
  avatarUrl: string;
  subscriptionType: SubscriptionType;
  images: Image[];
  relatedGenerationsResp: {
    generations: ExploreRelatedImage[];
    remainingGenIds: number[];
  };
  additionalGenerations: {
    id: number;
    slug: string;
    createdAt: string;
  }[];
};

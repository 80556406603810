import { Nullable } from "./common";
import { ViewMode } from "./thread";

export enum SubscriptionType {
  Free = "free",
  Starter = "starter",
  Premium = "premium",
  Professional = "professional",
  Business = "business",
}

export type ActiveJob = {
  id: number;
  threadUUID: string;
  createdAt: string;
};

export type User = {
  id: number;
  email: string;
  username: string;
  avatarUrl: Nullable<string>;
  availableCredits: number;
  signupType: string;
  subscriptionType: SubscriptionType;
  lastGenerationDate: Nullable<string>;
  billingCustomerId: Nullable<string>;
  billingProvider: Nullable<string>;
  subscriptionStartDate: Nullable<string>;
  subscriptionEndDate: Nullable<string>;
  trialEndDate: Nullable<string>;
  trialCancelled: boolean;
  subscribedToEmailList: boolean;
  isActive: boolean;
  accountDeletionDate: Nullable<string>;
  hasLifetimeAccess: boolean;
  defaultViewMode: ViewMode;
  onboardingComplete: boolean;
  lastImageEditDate: Nullable<string>;
  createdAt: string;
  updatedAt: string;
  isBanned: boolean;
  activeJobs: ActiveJob[];
  safeSearch: boolean;
  temporaryLogin?: string | null;
};

export type ResourceName =
  | "gen"
  | "hd"
  | "enhance"
  | "remix"
  | "fluxPro"
  | "hdTurbo"
  | "nsfw"
  | "inPaint";

export type PeriodType = "daily" | "monthly";

export type Quota = {
  id: string;
  resourceName: ResourceName;
  periodType: PeriodType;
  currentQuota: number;
  baseQuota: number;
};

"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import { User } from "@/types/user";
import { ViewMode } from "@/types/thread";
import { AppDispatch, RootState } from "@/redux/store";
import {
  setIsAuthenticated,
  setUser,
  setUserLoading,
  generateAlert,
  setUserUpdateLoading,
  setUserUpdateError,
  authenticateUser,
  setPromoCode,
  setPromoCodeError,
  setJobStatus,
  setJobType,
  setQuotas,
} from ".";

export const registerUser = createAsyncThunk<
  boolean,
  { email: string; password: string; subscribeToEmailList: boolean },
  { dispatch: AppDispatch; state: RootState }
>(
  "user/registerUser",
  async ({ email, password, subscribeToEmailList }, { dispatch }) => {
    try {
      dispatch(setUserLoading(true));
      const reqBody = { email, password, subscribeToEmailList };
      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/user`,
        reqBody,
        {
          withCredentials: true,
        }
      );
      const user = response.data;
      dispatch(setUser(user));
      dispatch(setIsAuthenticated(true));
      dispatch(
        generateAlert({
          text: "User registration successful!",
          type: "success",
        })
      );
      return true;
    } catch (error: unknown) {
      let errorMessage = "Failed to register new user";
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      if (error instanceof AxiosError) {
        error.response?.data?.errors?.forEach((error: { msg: string }) => {
          dispatch(
            generateAlert({
              text: error.msg,
              type: "error",
            })
          );
        });
      } else {
        dispatch(
          generateAlert({
            text: errorMessage,
            type: "error",
          })
        );
      }
      dispatch(setUserLoading(false));
      return false;
    }
  }
);

export const updateUsername = createAsyncThunk<
  boolean,
  string,
  { dispatch: AppDispatch; state: RootState }
>("user/updateUsername", async (username, { dispatch }) => {
  try {
    dispatch(setUserUpdateLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const reqData = JSON.stringify({ username: username.trim() });

    const updatedUser = await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/user/username`,
      reqData,
      config
    );

    dispatch(setUser(updatedUser.data));

    dispatch(
      generateAlert({
        text: "Username updated successfully!",
        type: "success",
      })
    );

    dispatch(setUserUpdateLoading(false));
    dispatch(setUserUpdateError(""));
    return true;
  } catch (error: any) {
    console.error(error);
    dispatch(setUserUpdateLoading(false));
    const errorMessage =
      error.response?.data?.errors[0]?.msg || "Failed to update username";
    dispatch(setUserUpdateError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    return false;
  }
});

export const updateUserEmail = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch; state: RootState }
>("user/updateUserEmail", async (email, { dispatch }) => {
  try {
    dispatch(setUserUpdateLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const reqData = JSON.stringify({ email });

    const updatedUser = await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/user/email`,
      reqData,
      config
    );

    dispatch(setUser(updatedUser.data));

    dispatch(
      generateAlert({
        text: "Email address updated successfully!",
        type: "success",
      })
    );

    dispatch(setUserUpdateLoading(false));
    dispatch(setUserUpdateError(""));
  } catch (error: any) {
    console.error(error);
    dispatch(setUserUpdateLoading(false));
    const errorMessage =
      error.response?.data?.errors[0]?.msg || "Failed to update email address";
    dispatch(setUserUpdateError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const updateUserPassword = createAsyncThunk<
  void,
  { oldPassword: string; newPassword: string },
  { dispatch: AppDispatch; state: RootState }
>(
  "user/updateUserPassword",
  async ({ oldPassword, newPassword }, { dispatch }) => {
    try {
      dispatch(setUserUpdateLoading(true));

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      };

      const reqData = JSON.stringify({ oldPassword, newPassword });

      await axios.patch(
        `${process.env.NEXT_PUBLIC_API_URL}/user/password`,
        reqData,
        config
      );

      dispatch(
        generateAlert({
          text: "Password updated successfully!",
          type: "success",
        })
      );

      dispatch(setUserUpdateLoading(false));
      dispatch(setUserUpdateError(""));
    } catch (error: any) {
      console.error(error);
      dispatch(setUserUpdateLoading(false));
      const errorMessage =
        error.response?.data?.errors[0]?.msg || "Password update failed";
      dispatch(setUserUpdateError(errorMessage));
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);

export const updateAvatar = createAsyncThunk<
  User | null,
  Blob | File | string,
  { dispatch: AppDispatch; state: RootState }
>("user/updateAvatar", async (image, { dispatch }) => {
  try {
    dispatch(setUserUpdateLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const reqData = new FormData();
    reqData.append("image", image);

    const updatedUser = await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/user/avatar`,
      reqData,
      config
    );

    dispatch(setUser(updatedUser.data));

    dispatch(
      generateAlert({
        text: "Avatar updated successfully!",
        type: "success",
      })
    );

    dispatch(setUserUpdateLoading(false));
    dispatch(setUserUpdateError(""));
    return updatedUser.data;
  } catch (error: any) {
    console.error(error);
    dispatch(setUserUpdateLoading(false));
    const errorMessage =
      error.response?.data?.errors[0]?.msg || "Failed to update avatar";
    dispatch(setUserUpdateError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    return null;
  }
});

export const updateDefaultViewMode = createAsyncThunk<
  void,
  ViewMode,
  { dispatch: AppDispatch; state: RootState }
>("user/updateDefaultViewMode", async (viewMode, { dispatch }) => {
  try {
    dispatch(setUserUpdateLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const requestData = JSON.stringify({ defaultViewMode: viewMode });

    const updatedUser = await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/user/defaultViewMode`,
      requestData,
      config
    );

    dispatch(setUser(updatedUser.data));

    dispatch(
      generateAlert({
        text: "Default generation privacy updated!",
        type: "success",
      })
    );

    dispatch(setUserUpdateLoading(false));
    dispatch(setUserUpdateError(""));
  } catch (error: any) {
    console.error(error);
    dispatch(setUserUpdateLoading(false));
    const errorMessage =
      error.response?.data?.errors[0]?.msg ||
      "Failed to update default generation privacy";
    dispatch(setUserUpdateError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const updateOnboardingCompleted = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("user/updateOnboardingCompleted", async (_, { dispatch }) => {
  try {
    dispatch(setUserUpdateLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const requestData = JSON.stringify({ onboardingComplete: true });

    const updatedUser = await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/user/onboardingComplete`,
      requestData,
      config
    );

    dispatch(setUser(updatedUser.data));
    dispatch(setUserUpdateLoading(false));
  } catch (error: any) {
    console.error(error);
    dispatch(setUserUpdateLoading(false));
  }
});

export const deleteUser = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("user/deleteUser", async (_, { dispatch }) => {
  try {
    dispatch(setUserUpdateError(""));
    dispatch(setUserUpdateLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/user`, config);

    dispatch(
      generateAlert({
        text: "Account deleted successfully!",
        type: "success",
      })
    );

    dispatch(setUserUpdateLoading(false));
    dispatch(setUserUpdateError(""));
  } catch (error: any) {
    console.error(error);
    dispatch(setUserUpdateLoading(false));
    const errorMessage =
      error.response?.data?.errors[0]?.msg || "Failed to delete user account";
    dispatch(setUserUpdateError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const redeemTrialCoupon = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch; state: RootState }
>("user/deleteUser", async (code, { dispatch }) => {
  try {
    dispatch(setPromoCodeError(""));
    dispatch(setUserUpdateLoading(true));

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };

    const requestBody = {
      code,
    };

    await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/coupon/redeem/freeTrial`,
      requestBody,
      config
    );

    dispatch(authenticateUser());
    dispatch(setPromoCode(null));

    dispatch(
      generateAlert({
        text: "Free trial activated!",
        type: "success",
      })
    );

    dispatch(setUserUpdateLoading(false));
    dispatch(setPromoCodeError(""));
  } catch (error: any) {
    console.error(error);
    dispatch(setUserUpdateLoading(false));
    const errorMessage = error.response?.data || "Failed to redeem free trial";
    dispatch(setPromoCodeError(errorMessage));
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const cancelActiveJob = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("user/cancelActiveJob", async (jobId, { dispatch }) => {
  try {
    const response = await axios.delete(
      `${process.env.NEXT_PUBLIC_API_URL}/user/activeJob/${jobId}`,
      {
        withCredentials: true,
      }
    );
    dispatch(setUser(response.data));
    dispatch(setJobStatus(null));
    dispatch(setJobType(null));
  } catch (error) {
    console.error(error);
    dispatch(setJobStatus(null));
    dispatch(setJobType(null));
    dispatch(authenticateUser());
  }
});

export const updateSafeSearch = createAsyncThunk<
  void,
  boolean,
  { dispatch: AppDispatch; state: RootState }
>("user/updateSafeSearch", async (safeSearch, { dispatch }) => {
  try {
    const response = await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/user/safeSearch`,
      { safeSearch },
      {
        withCredentials: true,
      }
    );
    dispatch(setUser(response.data));
  } catch (error: unknown) {
    console.error(error);
  }
});

export const getQuotas = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState }
>("user/getQuotas", async (_, { dispatch }) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/quota`,
      {
        withCredentials: true,
      }
    );
    dispatch(setQuotas(response.data));
  } catch (error: any) {
    console.error(error);
  }
});

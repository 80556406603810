"use client";

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";

import { AlertDialogType } from "@/types/alert";
import { TransformationType, ViewMode } from "@/types/thread";
import { AppDispatch, RootState } from "@/redux/store";
import {
  generateAlert,
  setRoomId,
  setRoomUserId,
  setGenerations,
  setJobStatus,
  setJobType,
  setCancelled,
  setCancellationReason,
  setLoadingThread,
  setUsername,
  setAvatarUrl,
  setSubscriptionType,
  setUserFollow,
  resetGallery,
  setUser,
  cancelActiveJob,
  addAlertDialog,
  getQuotas,
} from ".";
import { dataURLtoBlob } from "@/utils/url";

export const getRoomById = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("room/getRoomById", async (roomId, { dispatch }) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/room/${roomId}`,
      {
        withCredentials: true,
      }
    );
    if (Array.isArray(response.data.generations)) {
      dispatch(setGenerations(response.data.generations));
    }
  } catch (error: unknown) {
    console.error(error);
    let errorMessage = "Failed to fetch rooms";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const createRoom = createAsyncThunk<
  string | null,
  {
    inspiration?: (Blob | string)[];
    prompt?: string;
    landingPageSlug?: string;
    defaultAspectRatio?: string | null;
    imageMask?: Blob | null;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "room/createRoom",
  async (
    {
      inspiration = [],
      prompt = "",
      landingPageSlug,
      defaultAspectRatio,
      imageMask = null,
    },
    { dispatch, getState }
  ) => {
    try {
      dispatch(setJobStatus("preload"));
      dispatch(setCancelled(false));
      const selectedModel = getState().room.selectedModel;
      const selectedAspectRatio = getState().room.selectedAspectRatio;
      const viewMode = getState().room.viewMode;
      const user = getState().user.user;
      const isSubscribed = user && user?.subscriptionType !== "free";

      const postConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };

      const reqData = new FormData();
      reqData.append("name", "Untitled");
      reqData.append("viewMode", viewMode);
      reqData.append("prompt", prompt || "");

      if (landingPageSlug) {
        reqData.append("landingPageSlug", landingPageSlug);
      }

      let modelUsed = selectedModel;
      if (inspiration.length > 0) {
        modelUsed = TransformationType.Remix;
      }

      if (imageMask && inspiration.length === 1) {
        modelUsed = TransformationType.InPaint;
      }

      if (isSubscribed) {
        reqData.append("aspectRatio", selectedAspectRatio);
      } else {
        reqData.append("aspectRatio", defaultAspectRatio || "1:1");
      }

      if (modelUsed === TransformationType.Remix) {
        if (inspiration.length > 0) {
          const updatedInspiration = [...inspiration];
          updatedInspiration.forEach((image, index) => {
            if (typeof image === "string" && image.startsWith("data:image")) {
              const blob = dataURLtoBlob(image);
              updatedInspiration[index] = blob;
            }
          });
          const rawInspiration = updatedInspiration.filter(
            (image) => typeof image !== "string"
          ) as Blob[];
          const urlInspiration = updatedInspiration.filter(
            (image) => typeof image === "string"
          ) as string[];

          rawInspiration.forEach((image, index) => {
            reqData.append("inspiration", image, `inspiration_${index + 1}`);
          });

          urlInspiration.forEach((url) => {
            reqData.append("inspirationImageUrls", url);
          });
        }
      }

      if (modelUsed === TransformationType.InPaint) {
        reqData.append("mask", imageMask as Blob, "image_mask");

        const updatedInspiration = [...inspiration];
        updatedInspiration.forEach((image, index) => {
          if (typeof image === "string" && image.startsWith("data:image")) {
            const blob = dataURLtoBlob(image);
            updatedInspiration[index] = blob;
          }
        });
        const rawInspiration = updatedInspiration.filter(
          (image) => typeof image !== "string"
        ) as Blob[];
        const urlInspiration = updatedInspiration.filter(
          (image) => typeof image === "string"
        ) as string[];

        rawInspiration.forEach((image, index) => {
          reqData.append("inspiration", image, `inspiration_${index + 1}`);
        });

        urlInspiration.forEach((url) => {
          reqData.append("inspirationImageUrls", url);
        });
      }

      reqData.append("transformationType", modelUsed);

      const response = await axios.post(
        `${process.env.NEXT_PUBLIC_API_URL}/room`,
        reqData,
        postConfig
      );

      dispatch(setUser(response.data.user));
      return response.data.user.activeJobs[0]?.threadUUID || null;
    } catch (error: any) {
      console.error(error);
      let errorMessage = "Failed to generate idea";
      if (error instanceof Error) {
        errorMessage = error.message;
      }

      if (errorMessage.includes("400")) {
        dispatch(
          addAlertDialog({
            title: "Quota Limit Reached",
            description:
              "You have reached your quota limit. Please upgrade your subscription to continue.",
            nextButtonText: "Upgrade",
            type: AlertDialogType.SubscriptionRequired,
          })
        );
      } else {
        dispatch(generateAlert({ text: errorMessage, type: "error" }));
      }

      dispatch(setJobStatus(null));
      return null;
    }
  }
);

export const checkRoomJobStatus = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("room/checkJobStatus", async (jobId, { dispatch, getState }) => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/room/job/${jobId}`,
      {
        withCredentials: true,
      }
    );

    const generations = response.data.generations;
    const jobStatus = response.data.jobStatus;
    const roomId = response.data.id;
    const cancellationReason = response.data.cancellationReason;

    dispatch(setJobStatus(jobStatus));

    if (jobStatus === "cancelled") {
      dispatch(setCancelled(true));
      dispatch(setCancellationReason(cancellationReason));
      dispatch(getRoomById(roomId));
      dispatch(cancelActiveJob(jobId));
    }

    if (jobStatus === "failed") {
      dispatch(getRoomById(roomId));
      dispatch(cancelActiveJob(jobId));
      dispatch(
        generateAlert({
          text: "Generation failed, please try again.",
          type: "error",
        })
      );
    }
    if (
      ["submitted", "preparing", "prepared", "in_progress"].includes(
        jobStatus || ""
      )
    ) {
      dispatch(setJobType(response.data.transformationType));
    }

    if (jobStatus === "processed") {
      const user = getState().user.user;
      const threadUUID = user?.activeJobs?.[0]?.threadUUID;
      const threadUrl = threadUUID ? `/idea/${threadUUID}` : "/";

      dispatch(cancelActiveJob(jobId));
      if (Array.isArray(generations)) {
        dispatch(setGenerations(generations));
      }
      dispatch(getRoomById(roomId));
      dispatch(resetGallery());
      dispatch(getQuotas());
      const alerts = getState().alert.alerts;
      if (
        !alerts.some(
          (alert) =>
            typeof alert.text === "string" &&
            alert.text.includes("Generation Successful")
        )
      ) {
        dispatch(
          generateAlert({
            text: (
              <a
                href={threadUrl}
                style={{
                  color: "inherit",
                  whiteSpace: "nowrap",
                }}
              >
                Generation Successful
              </a>
            ),
            type: "success",
          })
        );
      }
    }
  } catch (error: unknown) {
    console.error(error);
  }
});

export const getNewGenerations = createAsyncThunk<
  string | null,
  {
    prompt?: string;
    inspiration?: (Blob | string)[];
    imageId?: number;
    imageEnhance?: boolean;
    defaultAspectRatio?: string | null;
    imageMask?: Blob | null;
  },
  { dispatch: AppDispatch; state: RootState }
>(
  "room/getNewGenerations",
  async (
    {
      prompt,
      inspiration = [],
      imageId,
      imageEnhance = false,
      defaultAspectRatio,
      imageMask = null,
    },
    { dispatch, getState }
  ) => {
    try {
      const selectedModel = getState().room.selectedModel;
      const selectedAspectRatio = getState().room.selectedAspectRatio;
      const viewMode = getState().room.viewMode;
      const roomId = getState().room.roomId;
      const user = getState().user.user;
      const isSubscribed = user && user?.subscriptionType !== "free";

      if (!roomId) {
        throw new Error("Room ID is required to generate new generations");
      }

      let modelUsed = selectedModel;
      if (inspiration && inspiration.length > 0) {
        modelUsed = TransformationType.Remix;
      } else if (modelUsed === TransformationType.Remix) {
        modelUsed = isSubscribed
          ? TransformationType.HD
          : TransformationType.Turbo;
      }

      if (imageEnhance) {
        modelUsed = TransformationType.Enhance;
      } else if (modelUsed === TransformationType.Enhance) {
        modelUsed = isSubscribed
          ? TransformationType.HD
          : TransformationType.Turbo;
      }

      if (imageMask) {
        modelUsed = TransformationType.InPaint;
      }

      const reqData = new FormData();
      reqData.append("prompt", prompt || "");
      reqData.append("viewMode", viewMode);
      reqData.append("transformationType", modelUsed);

      if (isSubscribed) {
        reqData.append("aspectRatio", selectedAspectRatio);
      } else {
        reqData.append("aspectRatio", defaultAspectRatio || "1:1");
      }

      if (modelUsed === TransformationType.Remix) {
        if (inspiration && inspiration.length > 0) {
          const updatedInspiration = [...inspiration];
          updatedInspiration.forEach((image, index) => {
            if (typeof image === "string" && image.startsWith("data:image")) {
              const blob = dataURLtoBlob(image);
              updatedInspiration[index] = blob;
            }
          });
          const rawInspiration = updatedInspiration.filter(
            (image) => typeof image !== "string"
          ) as Blob[];
          const urlInspiration = updatedInspiration.filter(
            (image) => typeof image === "string"
          ) as string[];

          rawInspiration.forEach((image, index) => {
            reqData.append("inspiration", image, `inspiration_${index + 1}`);
          });

          urlInspiration.forEach((url) => {
            reqData.append("inspirationImageUrls", url);
          });
        }
      }

      if (modelUsed === TransformationType.InPaint) {
        reqData.append("mask", imageMask as Blob, "image_mask");

        const updatedInspiration = [...inspiration];
        updatedInspiration.forEach((image, index) => {
          if (typeof image === "string" && image.startsWith("data:image")) {
            const blob = dataURLtoBlob(image);
            updatedInspiration[index] = blob;
          }
        });
        const rawInspiration = updatedInspiration.filter(
          (image) => typeof image !== "string"
        ) as Blob[];
        const urlInspiration = updatedInspiration.filter(
          (image) => typeof image === "string"
        ) as string[];

        rawInspiration.forEach((image, index) => {
          reqData.append("inspiration", image, `inspiration_${index + 1}`);
        });

        urlInspiration.forEach((url) => {
          reqData.append("inspirationImageUrls", url);
        });
      }

      if (modelUsed === TransformationType.Enhance) {
        if (!imageId) throw new Error("Image ID is required to enhance image");
        reqData.append("imageId", imageId.toString());
      }

      const roomResponse = await axios.patch(
        `${process.env.NEXT_PUBLIC_API_URL}/room/retry/${roomId}`,
        reqData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      dispatch(setUser(roomResponse.data.user));
      return roomResponse.data.user.activeJobs[0]?.threadUUID || null;
    } catch (error: unknown) {
      console.error(error);
      let errorMessage = "Generation failed";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      if (errorMessage.includes("400")) {
        dispatch(
          addAlertDialog({
            title: "Quota Limit Reached",
            description:
              "You have reached your quota limit. Please upgrade your subscription to continue.",
            nextButtonText: "Upgrade",
            type: AlertDialogType.SubscriptionRequired,
          })
        );
      } else {
        dispatch(generateAlert({ text: errorMessage, type: "error" }));
      }
      dispatch(setJobStatus(null));
      return null;
    }
  }
);

export const deleteRoom = createAsyncThunk<
  void,
  number,
  { dispatch: AppDispatch; state: RootState }
>("room/deleteRoom", async (roomId, { dispatch }) => {
  try {
    dispatch(setLoadingThread(true));
    await axios.delete(`${process.env.NEXT_PUBLIC_API_URL}/room/${roomId}`, {
      withCredentials: true,
    });
    dispatch(generateAlert({ text: "Idea deleted", type: "success" }));
    dispatch(resetGallery());
    dispatch(setLoadingThread(false));
  } catch (error: unknown) {
    let errorMessage = "Failed to delete idea";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
    dispatch(setLoadingThread(false));
  }
});

export const renameRoom = createAsyncThunk<
  void,
  { roomId: number; name: string },
  { dispatch: AppDispatch; state: RootState }
>("room/renameRoom", async ({ roomId, name }, { dispatch }) => {
  try {
    await axios.patch(
      `${process.env.NEXT_PUBLIC_API_URL}/room/${roomId}`,
      {
        name,
      },
      {
        withCredentials: true,
      }
    );

    dispatch(getRoomById(roomId));
    dispatch(generateAlert({ text: "Room renamed", type: "success" }));
  } catch (error: unknown) {
    let errorMessage = "Failed to rename room";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
    dispatch(generateAlert({ text: errorMessage, type: "error" }));
  }
});

export const loadThread = createAsyncThunk<
  void,
  string,
  { dispatch: AppDispatch; state: RootState }
>("room/loadThread", async (roomUUID, { dispatch }) => {
  try {
    dispatch(setLoadingThread(true));

    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/room/public/${roomUUID}`,
      {
        withCredentials: true,
      }
    );

    if (response.data.id) {
      dispatch(setRoomId(response.data.id));
    }
    dispatch(setRoomUserId(response.data.userId));
    if (Array.isArray(response.data.generations)) {
      dispatch(setGenerations(response.data.generations));
    }
    dispatch(setUsername(response.data.username));
    dispatch(setAvatarUrl(response.data.avatarUrl));
    dispatch(setSubscriptionType(response.data.subscriptionType));
    dispatch(setUserFollow(response.data.userFollow));
  } catch (error: unknown) {
    console.error(error);
    let errorMessage = "Failed to fetch ideas";
    if (error instanceof Error) {
      errorMessage = error.message;
    }

    if (error instanceof AxiosError) {
      if (error.response?.data !== "Idea not found") {
        dispatch(generateAlert({ text: errorMessage, type: "error" }));
      }
    }
    dispatch(setLoadingThread(false));
  }
});

export const updateGenerationViewMode = createAsyncThunk<
  boolean,
  { generationId: number; viewMode: ViewMode },
  { dispatch: AppDispatch; state: RootState }
>(
  "room/updateGenerationViewMode",
  async ({ generationId, viewMode }, { dispatch }) => {
    try {
      const requestBody = {
        viewMode,
        generationIds: [generationId],
      };

      await axios.patch(
        `${process.env.NEXT_PUBLIC_API_URL}/generation/viewMode`,
        requestBody,
        {
          withCredentials: true,
        }
      );
      dispatch(
        generateAlert({
          text: `Generation visibility set to ${viewMode}`,
          type: "success",
        })
      );
      return true;
    } catch (error: unknown) {
      console.error(error);
      let errorMessage = "Failed to update generation view mode";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
      return false;
    }
  }
);

export const deleteGeneration = createAsyncThunk<
  void,
  { generationId: number },
  { dispatch: AppDispatch; state: RootState }
>(
  "generation/deleteGeneration",
  async ({ generationId }, { dispatch, getState }) => {
    try {
      const roomId = getState().room.roomId;

      await axios.delete(
        `${process.env.NEXT_PUBLIC_API_URL}/generation/${generationId}`,
        {
          withCredentials: true,
        }
      );

      dispatch(
        generateAlert({
          text: "Idea deleted successfully!",
          type: "success",
        })
      );
      if (roomId) dispatch(getRoomById(roomId));
    } catch (error: unknown) {
      console.error(error);
      const errorMessage = "Failed to delete idea";
      dispatch(generateAlert({ text: errorMessage, type: "error" }));
    }
  }
);
